/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderInlineDataFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type routeConfig_PublicRedirectRoute_referralCode$ref: FragmentReference;
declare export opaque type routeConfig_PublicRedirectRoute_referralCode$fragmentType: routeConfig_PublicRedirectRoute_referralCode$ref;
export type routeConfig_PublicRedirectRoute_referralCode = {|
  +code: ?string,
  +program: ?{|
    +options: ?$ReadOnlyArray<{|
      +key: string,
      +value: ?string,
    |}>
  |},
  +$refType: routeConfig_PublicRedirectRoute_referralCode$ref,
|};
export type routeConfig_PublicRedirectRoute_referralCode$data = routeConfig_PublicRedirectRoute_referralCode;
export type routeConfig_PublicRedirectRoute_referralCode$key = {
  +$data?: routeConfig_PublicRedirectRoute_referralCode$data,
  +$fragmentRefs: routeConfig_PublicRedirectRoute_referralCode$ref,
  ...
};
*/


const node/*: ReaderInlineDataFragment*/ = {
  "kind": "InlineDataFragment",
  "name": "routeConfig_PublicRedirectRoute_referralCode"
};
// prettier-ignore
(node/*: any*/).hash = '2b7a9ecc84b4d6031e0da23c0fbec52f';

module.exports = node;
