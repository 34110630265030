import { makeRouteConfig, Route, Redirect, RedirectException } from 'found';
import React, { lazy } from 'react';
import { readInlineData } from 'react-relay';
import LoadingIndicator from '../components/LoadingIndicator';
import AppPage from '../containers/AppPage';
import PublicPage from '../containers/PublicPage';

const LoginPage = lazy(() => import('../pages/LoginPage'));
const NewProgramPage = lazy(() => import('../pages/NewProgramPage'));
const PollProgramPage = lazy(() => import('../pages/PollProgramPage'));
const PollPage = lazy(() => import('../pages/PollPage'));
const CreateEditPollPage = lazy(() => import('../pages/CreateEditPollPage'));
const ProgramPage = lazy(() => import('../pages/ProgramPage'));
const ProgramsPage = lazy(() => import('../pages/ProgramsPage'));
const ReferrerPage = lazy(() => import('../pages/ReferrerPage'));
const PublicReferPage = lazy(() => import('../pages/PublicReferPage'));
const PublicUserPage = lazy(() => import('../pages/PublicUserPage'));
const IframeReferPage = lazy(() => import('../pages/IframeReferPage'));
const IframeUserPage = lazy(() => import('../pages/IframeUserPage'));

function hasValidUrlTemplate(urlTemplate) {
  return !!urlTemplate && urlTemplate.match(/\[CODE\]/);
}

function urlFromTemplate(urlTemplate, code, type = 'refer') {
  if (!code) {
    throw new Error('Invalid code.');
  }
  const defaultUrl = `${document.location.protocol}//${document.location.host}/${type}/${code}`;
  const url = hasValidUrlTemplate(urlTemplate) ? urlTemplate.replace(/\[CODE\]/, code) : defaultUrl;
  return url;
}

class AuthenticatedRoute extends Route {
  render({ Component, props, retry }) {
    if (Component && props) {
      const context = props.context || props.match.context;
      const { app } = context.store.getState();
      if (app && app.viewer && app.viewer.token) {
        return <Component {...props} retry={retry} />
      } else {
        throw new RedirectException("/login");
      }
    }
    return <LoadingIndicator />
  }
}

class PublicRedirectRoute extends Route {
  render({ Component, props, retry }) {
    if (Component && props) {
      const { match, referralCode } = props;
      const data = readInlineData(graphql`
        fragment routeConfig_PublicRedirectRoute_referralCode on ReferralCode @inline {
          code
          program {
            options {
              key
              value
            }
          }
        }
      `, referralCode);

      const { redirect = true } = match.location.query;
      if (JSON.parse(redirect)) {
        const type = match.route.path.replace(/^([^/]*).*/, '$1');
        const { code, program } = data;
        const options = Object.fromEntries(program.options.map(option => Object.values(option)) || []);
        const { refer_url: referUrlTemplate, user_url: userUrlTemplate } = options;
        const urlTemplate = type === 'refer' ? referUrlTemplate : userUrlTemplate;
  
        if (hasValidUrlTemplate(urlTemplate)) {
          const redirectUrl = urlFromTemplate(urlTemplate, code, type);
          document.location = redirectUrl;
          return <LoadingIndicator />
        }
      }

      return <Component {...props} retry={retry} />
    }
    return <LoadingIndicator />
  }
}

const routeConfig = makeRouteConfig(
  <Route path="/">
    <AuthenticatedRoute Component={AppPage} query={graphql`
      query routeConfig_AppPrograms_Query {
        viewer {
          ...AppPage_viewer
          ...routeConfig_AppPrograms_User
        }
        pollPrograms {
          ...AppPage_pollPrograms
        }
        programs {
          ...AppPage_programs
        }
      }
    `}
    render={({ props }) => {
      if (!props) {
        return null;
      }
      // redirect to login if not logged in or user id doesn't match app state
      const { match } = props;
      const { id: viewerId } = readInlineData(graphql`
        fragment routeConfig_AppPrograms_User on User @inline {
          id
        }
      `, props.viewer) || {};
      const { app } = match.context.store.getState();
      const { viewer } = app;
      if (!viewer || !viewer.id || !viewerId || viewerId !== viewer.id) {
        throw new RedirectException("/login");
      }
      return <AppPage {...{...props }} />
    }}>
      {/* Redirect to last viewed program */}
      <Redirect from="/" to={({ context }) => {
        const { app } = context.store.getState();
        return !app.viewer || !app.viewer.token ? '/login' : `/programs`;
      }} />

      <AuthenticatedRoute path="programs" Component={ProgramsPage} query={graphql`
          query routeConfig_Programs_Query {
            programs {
              ...ProgramsPage_programs
            }
            pollPrograms {
              ...ProgramsPage_pollPrograms
            }
          }
        `}
        render={({ props }) => {
          if (!props) {
            return null;
          }
          const { programs = null, pollPrograms = null } = props;
          return !props ? null : <ProgramsPage {...{...props, programs, pollPrograms }} />}
        }
      />

      {/* Single poll program */}
      <AuthenticatedRoute path="polls" Component={PollProgramPage} query={graphql`
          query routeConfig_PollProgram_Query {
            pollProgram(itemId: 1) {
              ...PollProgramPage_pollProgram
            }
            latestProgram: pollProgram(itemId: 1) {
              ...PollProgramPage_latestProgram
            }
          }
        `}
      />

      {/* Single poll */}
      <AuthenticatedRoute path="polls/:itemId(\d+)" Component={PollPage} query={graphql`
          query routeConfig_SinglePoll_Query($itemId: Int) {
            pollProgram(itemId: 1) {
              ...PollPage_pollProgram
            }
            poll(itemId: $itemId) {
              ...PollPage_poll
            }
          }
        `}
        prepareVariables={({ itemId, ...params }) => ({
            ...params,
            itemId: itemId && parseInt(itemId, 10)
          })
        }
      />

      {/* Create poll */}
      <AuthenticatedRoute path="polls/new" Component={CreateEditPollPage} query={graphql`
          query routeConfig_CreatePoll_Query {
            pollProgram(itemId: 1) {
              ...CreateEditPollPage_pollProgram
            }
          }
        `}
        render={({ props, retry }) => !props ? null : <CreateEditPollPage {...{...props, poll: (props.poll || null), retry}} />}
      />

      {/* Edit poll */}
      <AuthenticatedRoute path="polls/:itemId(\d+)/edit" Component={CreateEditPollPage} query={graphql`
          query routeConfig_EditPoll_Query($itemId: Int) {
            pollProgram(itemId: 1) {
              ...CreateEditPollPage_pollProgram
            }
            poll(itemId: $itemId) {
              ...CreateEditPollPage_poll
            }
          }
        `}
        prepareVariables={({ itemId, ...params }) => ({
            ...params,
            itemId: itemId && parseInt(itemId, 10)
          })
        }
      />

      {/* Single program */}
      <AuthenticatedRoute path="programs/:itemId(\d+)" Component={ProgramPage} query={graphql`
          query routeConfig_Program_Query($itemId: Int) {
            program(itemId: $itemId) {
              ...ProgramPage_program
            }
            fulfillmentProgram: program(itemId: $itemId) {
              ...ProgramPage_fulfillmentProgram
            }
            latestProgram: program(itemId: $itemId) {
              ...ProgramPage_latestProgram
            }
            leadersProgram: program(itemId: $itemId) {
              ...ProgramPage_leadersProgram
            }
          }
        `}
        prepareVariables={({ itemId, ...params }) => ({
            ...params,
            itemId: itemId && parseInt(itemId, 10)
          })
        }
      />

      {/* New program */}
      <AuthenticatedRoute path="programs/new" Component={NewProgramPage} query={graphql`
          query routeConfig_NewProgram_Query {
            accounts {
              ...NewProgramPage_accounts
            }
          }
        `}
      />

      {/* Single referrer */}
      <AuthenticatedRoute path="referrers/:code([a-zA-Z0-9]+)" Component={ReferrerPage} query={graphql`
          query routeConfig_Referrer_Query($code: String) {
            referralCode(code: $code, withCount: true) {
              ...ReferrerPage_referralCode
            }
            referrals: referralCode(code: $code) {
              ...ReferrerPage_referrals
            }
          }
        `}
      />
    </AuthenticatedRoute>

    <Route Component={PublicPage}>
      {/* Login */}
      <Route path="login" Component={LoginPage} />

      {/* Public user refer form */}
      <PublicRedirectRoute path="refer/:code([a-zA-Z0-9]+)" Component={PublicReferPage} query={graphql`
          query routeConfig_PublicRefer_Query($code: String) {
            referralCode(code: $code) {
              ...PublicReferPage_referralCode
              ...routeConfig_PublicRedirectRoute_referralCode
            }
          }
        `}
      />

      {/* Public user profile */}
      <PublicRedirectRoute path="user/:code([a-zA-Z0-9]+)" Component={PublicUserPage} query={graphql`
          query routeConfig_PublicUser_Query($code: String) {
            referralCode(code: $code, withCount: true) {
              ...ReferrerPage_referralCode
              ...routeConfig_PublicRedirectRoute_referralCode
            }
            referrals: referralCode(code: $code) {
              ...ReferrerPage_referrals
            }
          }
        `}
      />

      {/* iframe user refer form */}
      <Route path="iframe/refer/:code([a-zA-Z0-9]+)"
        render={({props}) => {
          return props ? <IframeReferPage {...props} /> : null;
        }}
        query={graphql`
          query routeConfig_IframeRefer_Query($code: String) {
            referralCode(code: $code) {
              ...PublicReferPage_referralCode
            }
          }
        `}
      />

      {/* iframe user profile */}
      <Route path="iframe/user/:code([a-zA-Z0-9]+)"
        render={({props}) => {
          return props ? <IframeUserPage {...props} /> : null;
        }}
        query={graphql`
          query routeConfig_IframeUser_Query($code: String) {
            referralCode(code: $code, withCount: true) {
              ...ReferrerPage_referralCode
            }
            referrals: referralCode(code: $code) {
              ...ReferrerPage_referrals
            }
          }
        `}
      />
    </Route>
  </Route>
);

export default routeConfig;