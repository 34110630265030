/**
 * @flow
 * @relayHash 802546442b9d0c16d480772b7f8b023d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProgramsPage_pollPrograms$ref = any;
type ProgramsPage_programs$ref = any;
export type routeConfig_Programs_QueryVariables = {||};
export type routeConfig_Programs_QueryResponse = {|
  +programs: ?{|
    +$fragmentRefs: ProgramsPage_programs$ref
  |},
  +pollPrograms: ?{|
    +$fragmentRefs: ProgramsPage_pollPrograms$ref
  |},
|};
export type routeConfig_Programs_Query = {|
  variables: routeConfig_Programs_QueryVariables,
  response: routeConfig_Programs_QueryResponse,
|};
*/


/*
query routeConfig_Programs_Query {
  programs {
    ...ProgramsPage_programs
  }
  pollPrograms {
    ...ProgramsPage_pollPrograms
  }
}

fragment ProgramsPage_pollPrograms on PollProgramConnection {
  edges {
    node {
      __typename
      id
      itemId
      name
    }
  }
}

fragment ProgramsPage_programs on ProgramConnection {
  edges {
    node {
      __typename
      id
      itemId
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "itemId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_Programs_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProgramConnection",
        "kind": "LinkedField",
        "name": "programs",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProgramsPage_programs"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PollProgramConnection",
        "kind": "LinkedField",
        "name": "pollPrograms",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProgramsPage_pollPrograms"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routeConfig_Programs_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProgramConnection",
        "kind": "LinkedField",
        "name": "programs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v0/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PollProgramConnection",
        "kind": "LinkedField",
        "name": "pollPrograms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PollProgramEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PollProgram",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v0/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "802546442b9d0c16d480772b7f8b023d",
    "metadata": {},
    "name": "routeConfig_Programs_Query",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2ef0ea71dacd0362f48c04d4db18d5a4';

module.exports = node;
