import { Environment, RecordSource, Store } from 'relay-runtime';
import { RelayNetworkLayer, authMiddleware } from 'react-relay-network-modern/es';
import UploadMiddleware from './upload-middleware';
import { store as reduxStore } from '../store';
import 'whatwg-fetch';

const source = new RecordSource();
const store = new Store(source);
const handlerProvider = null;
const network = new RelayNetworkLayer([
  authMiddleware({
    token: () => {
      const { app } = reduxStore.getState('app') || {};
      const { viewer } = app || {};
      const { token } = viewer || '';
      return token;
    }
  }),
  UploadMiddleware()
]);

const environment = new Environment({
  handlerProvider,
  network,
  store,
});

export default environment;