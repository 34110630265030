import { Link } from "found";
import { connect } from "react-redux";
import { Button, Dropdown, Form } from "react-bootstrap";
import React, { useState } from "react";
import { doLogout } from "../actions";
import EditProfileModal from './EditProfileModal';
import ProgramSettingsModal from './ProgramSettingsModal';
import LogoHorizontal from "../images/logo-scalemail-horizontal.svg";
import AngleDownIcon from "../images/icon-angle-down.svg";
import UserIcon from "../images/icon-user.svg";
import GearIcon from "../images/icon-gear.svg";

const ProgramsMenu = ({ app, pollPrograms, programs }) => {
  const [currentProgram, setCurrentProgram] = useState(app.currentProgram);

  const combinedPrograms = [].concat(programs ? programs.edges : [], pollPrograms ? pollPrograms.edges : []);
  const activeProgram = combinedPrograms.reduce((current, { node }) => {
    const { type: currentType, itemId: currentId } = currentProgram || {};
    const { __typename: nodeType, itemId: nodeId } = node;
    return (nodeType === currentType) && (nodeId === currentId) ? node : current;
  }, null);

  const programClickHandler = (program) => () => setCurrentProgram(program);

  return (
    <Dropdown className="app-program-menu" alignRight>
      <Dropdown.Toggle
        as={Button}
        variant="dark"
        size="sm"
        className="pt-2 h-100"
        id="program-select-toggle"
      >
        <AngleDownIcon
          style={{ color: "#999", width: "12px", height: "12px" }}
          aria-hidden="true"
        />
        <span className="program-select-toggle__label">Program:</span>
        <span className="program-select-toggle__program">
          {activeProgram ? activeProgram.name : "Add Program"}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {!programs || !programs.edges.length ? null : programs.edges.map(({ node }) => (
          <Dropdown.Item
            key={`program-link-${node.itemId}`}
            as={Link}
            onClick={programClickHandler({ type: node.__typename, itemId: node.itemId})}
            to={`/programs/${node.itemId}`}
          >
            {node.name}
          </Dropdown.Item>
        ))}
        {!programs || !programs.edges.length || !pollPrograms || !pollPrograms.edges.length ? null : <Dropdown.Divider />}
        {!pollPrograms ? null : pollPrograms.edges.map(({ node }) => (
          <Dropdown.Item
            key={`poll-program-link-${node.itemId}`}
            as={Link}
            onClick={programClickHandler({ type: node.__typename, itemId: node.itemId})}
            to={'/polls'}
          >
            {node.name}
          </Dropdown.Item>
        ))}
        {/*
          <Dropdown.Divider />
          <Dropdown.Item to="/programs/new" as={Link} onClick={programClickHandler(null)}>+ Add Program</Dropdown.Item>
        */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Header = ({ doLogout, app, pollPrograms, programs, retry }) => {
  const { currentProgram } = app;

  const [isLoading, setIsLoading] = useState(false);
  const [currentModal, setCurrentModal] = useState();

  return (
    <nav className="app-nav">
      <a href="#main" className="sr-only sr-only-focusable">
        Skip to Main Content
      </a>
      <Link to="/" className="app-logo-link">
        <LogoHorizontal aria-label="Scalemail" width="117" height="22" />
      </Link>

      {currentProgram && <ProgramsMenu {...{app, pollPrograms, programs}} />}

      {currentProgram && (
        <Button variant="dark" size="sm" className="app-program-settings-button" onClick={() => setCurrentModal('programSettings')}>
          <GearIcon aria-label="Program Settings" />
        </Button>
      )}

      <Dropdown className="app-user-menu" alignRight>
        <Dropdown.Toggle
          as={Button}
          variant="dark"
          size="sm"
          className="pt-2 h-100"
          id="user-select-toggle"
        >
          <UserIcon aria-label="User" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item as={Button} onClick={() => setCurrentModal('editProfile')}>
            Edit Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Button} onClick={doLogout}>
            Log out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <ProgramSettingsModal {...{
        app, 
        retry,
        isLoading, 
        setIsLoading, 
        setCurrentModal,
        show: currentModal === 'programSettings'
      }} />

      <EditProfileModal {...{
        app, 
        retry,
        isLoading, 
        setIsLoading, 
        setCurrentModal,
        show: currentModal === 'editProfile'
      }} />
    </nav>
  );
};

const mapStateToProps = ({ app }) => ({ app });

const mapDispatchToProps = (dispatch) => ({
  doLogout() {
    dispatch(doLogout());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
