/**
 * @flow
 * @relayHash 4c7e10d4a1a84e36f7993626f9daeab1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CreateEditPollPage_poll$ref = any;
type CreateEditPollPage_pollProgram$ref = any;
export type routeConfig_EditPoll_QueryVariables = {|
  itemId?: ?number
|};
export type routeConfig_EditPoll_QueryResponse = {|
  +pollProgram: ?{|
    +$fragmentRefs: CreateEditPollPage_pollProgram$ref
  |},
  +poll: ?{|
    +$fragmentRefs: CreateEditPollPage_poll$ref
  |},
|};
export type routeConfig_EditPoll_Query = {|
  variables: routeConfig_EditPoll_QueryVariables,
  response: routeConfig_EditPoll_QueryResponse,
|};
*/


/*
query routeConfig_EditPoll_Query(
  $itemId: Int
) {
  pollProgram(itemId: 1) {
    ...CreateEditPollPage_pollProgram
    id
  }
  poll(itemId: $itemId) {
    ...CreateEditPollPage_poll
    id
  }
}

fragment CreateEditPollPage_poll on Poll {
  id
  createdAt
  prompt
  responseCount
  choices {
    id
    description
    responseCount
    responseRatio
  }
}

fragment CreateEditPollPage_pollProgram on PollProgram {
  id
  options {
    key
    value
  }
  ...PollProgramHeader_pollProgram
}

fragment PollProgramHeader_pollProgram on PollProgram {
  id
  itemId
  name
  image {
    url
    width
    height
  }
  options {
    key
    value
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "itemId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "itemId",
    "value": 1
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "itemId",
    "variableName": "itemId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "responseCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_EditPoll_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PollProgram",
        "kind": "LinkedField",
        "name": "pollProgram",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateEditPollPage_pollProgram"
          }
        ],
        "storageKey": "pollProgram(itemId:1)"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Poll",
        "kind": "LinkedField",
        "name": "poll",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateEditPollPage_poll"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeConfig_EditPoll_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PollProgram",
        "kind": "LinkedField",
        "name": "pollProgram",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Option",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "pollProgram(itemId:1)"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Poll",
        "kind": "LinkedField",
        "name": "poll",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "prompt",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Choice",
            "kind": "LinkedField",
            "name": "choices",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "responseRatio",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "4c7e10d4a1a84e36f7993626f9daeab1",
    "metadata": {},
    "name": "routeConfig_EditPoll_Query",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4d1bbcdf23f4eac942f07f94bf35b24d';

module.exports = node;
