import { Actions as FarceActions } from 'farce';
import { SET_POLL_PROGRAM, SET_CURRENT_PROGRAM, SET_PROGRAM_OPTIONS, SET_POLL_PROGRAMS, SET_PROGRAMS, SET_VIEWER } from '../constants/ActionTypes';

export const setCurrentProgram = (currentProgram) => ({
  type: SET_CURRENT_PROGRAM,
  currentProgram
});

export const setPrograms = (programs) => ({
  type: SET_PROGRAMS,
  programs
});

export const setPollProgram = (pollProgramId) => ({
  type: SET_POLL_PROGRAM,
  pollProgramId
});

export const setPollPrograms = (pollPrograms) => ({
  type: SET_POLL_PROGRAMS,
  pollPrograms
});

export const setProgramOptions = (options) => ({
  type: SET_PROGRAM_OPTIONS,
  ...options
});

export const redirectPrograms = program => {
  const { type = 'Program', itemId } = program;
  if ('PollProgram' === type) {
    return FarceActions.replace(`/polls`);
  } else {
    return FarceActions.replace(`/programs/${itemId}`);
  }
}

export const doLogin = user => {
  return (dispatch) => {
    if (user) {
      dispatch(setViewer(user));
      return dispatch(FarceActions.replace('/programs'));
    }
  }
};

export const doLogout = () => {
  return (dispatch) => {
    dispatch(setViewer());
    return dispatch(FarceActions.replace('/'));
  }
};

export const setViewer = (viewer) => {
  return (dispatch) => {
    return dispatch({
      type: SET_VIEWER,
      viewer
    });
  }
};