/**
 * @flow
 * @relayHash cc185e0b8b053e1d83a663c9ffe3b77a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserUpdateInput = {|
  currentPassword?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  password?: ?string,
  passwordConfirmation?: ?string,
|};
export type updateUserMutationVariables = {|
  input: UserUpdateInput
|};
export type updateUserMutationResponse = {|
  +userUpdate: {|
    +user: ?{|
      +id: string,
      +firstName: string,
      +lastName: string,
      +email: ?string,
      +token: string,
    |},
    +errors: ?$ReadOnlyArray<{|
      +message: string,
      +path: ?$ReadOnlyArray<string>,
    |}>,
  |}
|};
export type updateUserMutation = {|
  variables: updateUserMutationVariables,
  response: updateUserMutationResponse,
|};
*/


/*
mutation updateUserMutation(
  $input: UserUpdateInput!
) {
  userUpdate(input: $input) {
    user {
      id
      firstName
      lastName
      email
      token
    }
    errors {
      message
      path
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserUpdatePayload",
    "kind": "LinkedField",
    "name": "userUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "cc185e0b8b053e1d83a663c9ffe3b77a",
    "metadata": {},
    "name": "updateUserMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'facf5b39ffdfc19b15a1fe076e4b8c91';

module.exports = node;
