import React from "react";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import updateProgram from "../mutations/updateProgram";
import updatePollProgram from "../mutations/updatePollProgram";
import environment from "../config/relay-environment";

const ProgramSettingsModal = ({ app, retry, isLoading, setIsLoading, setCurrentModal, show }) => {
  const { currentProgram } = app;
  const { type: currentType, itemId: currentId } = currentProgram || {};
  const isPollProgram = 'PollProgram' === currentType;
  
  const programSettingsSuccess = () => {
    setIsLoading(false);
    setCurrentModal();
  };

  const handleProgramSettingsSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    const form = event.currentTarget;
    const updateMutation = isPollProgram ? updatePollProgram : updateProgram;
    const programInput = {
      itemId: currentId,
      options: isPollProgram ? [
        {
          key: "primary_color",
          value: form.elements["primary-color"].value.replace(/^#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])$/, '#$1$1$2$2$3$3')
        },
        {
          key: "hide_response_count",
          value: form.elements["hide-response-count"].checked ? "true" : null
        },
        {
          key: "subscribe_heading",
          value: form.elements["subscribe-heading"].value
        },
        {
          key: "subscribe_text",
          value: form.elements["subscribe-text"].value
        },
      ] : [
        {
          key: "display_default_refer_url",
          value: form.elements["display-default-refer-url"].checked ? "true" : null
        },
        {
          key: "primary_color",
          value: form.elements["primary-color"].value.replace(/^#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])$/, '#$1$1$2$2$3$3')
        },
        {
          key: "refer_url",
          value: form.elements["refer-url"].value
        },
        {
          key: "user_url",
          value: form.elements["user-url"].value
        },
        {
          key: "use_single_opt_in",
          value: form.elements["single-opt-in"].checked ? "true" : null
        },
        {
          key: "redirect_url",
          value: form.elements["redirect-url"].value
        },
      ]
    };

    updateMutation(environment, programInput)
      .then(retry)
      .then(programSettingsSuccess)
      .catch(error => console.error(error));
  }

  const defaultReferURL = `${document.location.protocol}//${document.location.host}/refer/[CODE]`;
  const defaultUserURL = `${document.location.protocol}//${document.location.host}/user/[CODE]`;

  return (
    <Modal
      show={show}
      onHide={setCurrentModal}
      aria-labelledby="program-settings"
      size="lg"
      centered
    >
      <Form onSubmit={handleProgramSettingsSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="program-settings" as="h4">
            Program Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Form.Row} controlId="primary-color">
            <Form.Label column sm={4}>Primary Color</Form.Label>
            <Col sm={8}>
              <Form.Control type="text" placeholder="#0ba3ba" defaultValue={app.primaryColor} />
            </Col>
          </Form.Group>
          {isPollProgram ? (
            <>
              <Form.Group as={Form.Row} controlId="hide-response-count">
                <Form.Label column sm={4}>Hide Response Count</Form.Label>
                <Col sm={8} className="col-form-label">
                  <Form.Check type="checkbox" id="hide-response-count" label="Don't display the number of responses on embedded polls." defaultChecked={app.hideResponseCount === "true"} />
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row} controlId="subscribe-heading">
                <Form.Label column sm={4}>Subscribe Heading</Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" placeholder="You need to be a subscriber to participate in this poll." defaultValue={app.subscribeHeading} aria-describedby="subscribe-heading-help" />
                  <Form.Text id="subscribe-heading-help" class="form-text text-muted">
                    Heading text for the subscribe prompt on an embedded poll.
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row} controlId="subscribe-text">
                <Form.Label column sm={4}>Subscribe Text</Form.Label>
                <Col sm={8}>
                  <Form.Control as="textarea" placeholder="Enter your email below to subscribe or verify your existing subscription." defaultValue={app.subscribeText} aria-describedby="subscribe-text-help" />
                  <Form.Text id="subscribe-text-help" class="form-text text-muted">
                    Descriptive text for the subscribe prompt on an embedded poll.
                  </Form.Text>
                </Col>
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group as={Form.Row} controlId="refer-url">
                <Form.Label column sm={4}>Public Refer URL</Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" placeholder={defaultReferURL} pattern="^https?://.*?\[CODE\].*" defaultValue={app.referURL} />
                  <Form.Check type="checkbox" id="display-default-refer-url" className="small mt-2" label="Display Scalemail refer URL on user page" style={{ lineHeight: 2 }} defaultChecked={app.displayDefaultReferURL === "true"} />
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row} controlId="user-url">
                <Form.Label column sm={4}>Public User URL</Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" placeholder={defaultUserURL} pattern="^https?://.*?\[CODE\].*" defaultValue={app.userURL} />
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row} controlId="redirect-url">
                <Form.Label column sm={4}>Thank You Redirect URL</Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" pattern="^https?://[^.]+\.[^.]+" defaultValue={app.redirectURL} />
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row} controlId="single-opt-in">
                <Form.Label column sm={4}>Single Opt In</Form.Label>
                <Col sm={8} className="col-form-label">
                  <Form.Check type="checkbox" id="single-opt-in" label="Use single opt-in when subscribing referred users" defaultChecked={app.useSingleOptIn === "true"} />
                </Col>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isLoading && (
            <Spinner animation="grow" role="status" variant="primary">
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
          <Button type="submit">Save</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ProgramSettingsModal;