/**
 * @flow
 * @relayHash 31c1b258776b4fa554e20f485f477700
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AppPage_pollPrograms$ref = any;
type AppPage_programs$ref = any;
type AppPage_viewer$ref = any;
type routeConfig_AppPrograms_User$ref = any;
export type routeConfig_AppPrograms_QueryVariables = {||};
export type routeConfig_AppPrograms_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: AppPage_viewer$ref & routeConfig_AppPrograms_User$ref
  |},
  +pollPrograms: ?{|
    +$fragmentRefs: AppPage_pollPrograms$ref
  |},
  +programs: ?{|
    +$fragmentRefs: AppPage_programs$ref
  |},
|};
export type routeConfig_AppPrograms_Query = {|
  variables: routeConfig_AppPrograms_QueryVariables,
  response: routeConfig_AppPrograms_QueryResponse,
|};
*/


/*
query routeConfig_AppPrograms_Query {
  viewer {
    ...AppPage_viewer
    ...routeConfig_AppPrograms_User
    id
  }
  pollPrograms {
    ...AppPage_pollPrograms
  }
  programs {
    ...AppPage_programs
  }
}

fragment AppPage_pollPrograms on PollProgramConnection {
  edges {
    node {
      __typename
      id
      itemId
      name
    }
  }
}

fragment AppPage_programs on ProgramConnection {
  edges {
    node {
      __typename
      id
      itemId
      name
    }
  }
}

fragment AppPage_viewer on User {
  id
}

fragment routeConfig_AppPrograms_User on User {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "itemId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_AppPrograms_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppPage_viewer"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "routeConfig_AppPrograms_User",
            "selections": (v1/*: any*/)
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PollProgramConnection",
        "kind": "LinkedField",
        "name": "pollPrograms",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppPage_pollPrograms"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProgramConnection",
        "kind": "LinkedField",
        "name": "programs",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppPage_programs"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routeConfig_AppPrograms_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PollProgramConnection",
        "kind": "LinkedField",
        "name": "pollPrograms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PollProgramEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PollProgram",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProgramConnection",
        "kind": "LinkedField",
        "name": "programs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "31c1b258776b4fa554e20f485f477700",
    "metadata": {},
    "name": "routeConfig_AppPrograms_Query",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '45fca2e7f26b7d162fe0e11c0b52756c';

module.exports = node;
