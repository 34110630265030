/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AppPage_pollPrograms$ref: FragmentReference;
declare export opaque type AppPage_pollPrograms$fragmentType: AppPage_pollPrograms$ref;
export type AppPage_pollPrograms = {|
  +edges: ?$ReadOnlyArray<?{|
    +node: ?{|
      +__typename: string,
      +id: string,
      +itemId: ?number,
      +name: ?string,
    |}
  |}>,
  +$refType: AppPage_pollPrograms$ref,
|};
export type AppPage_pollPrograms$data = AppPage_pollPrograms;
export type AppPage_pollPrograms$key = {
  +$data?: AppPage_pollPrograms$data,
  +$fragmentRefs: AppPage_pollPrograms$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppPage_pollPrograms",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PollProgramEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PollProgram",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PollProgramConnection",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '14e27e8280fb605c3d403af4e4a100ed';

module.exports = node;
