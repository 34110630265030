/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderInlineDataFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type routeConfig_AppPrograms_User$ref: FragmentReference;
declare export opaque type routeConfig_AppPrograms_User$fragmentType: routeConfig_AppPrograms_User$ref;
export type routeConfig_AppPrograms_User = {|
  +id: string,
  +$refType: routeConfig_AppPrograms_User$ref,
|};
export type routeConfig_AppPrograms_User$data = routeConfig_AppPrograms_User;
export type routeConfig_AppPrograms_User$key = {
  +$data?: routeConfig_AppPrograms_User$data,
  +$fragmentRefs: routeConfig_AppPrograms_User$ref,
  ...
};
*/


const node/*: ReaderInlineDataFragment*/ = {
  "kind": "InlineDataFragment",
  "name": "routeConfig_AppPrograms_User"
};
// prettier-ignore
(node/*: any*/).hash = '67551f13ec548fe358f9f6baea608f09';

module.exports = node;
