/**
 * @flow
 * @relayHash e5a608bd1f761824bcefeef6dd908f3c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CreateEditPollPage_pollProgram$ref = any;
export type routeConfig_CreatePoll_QueryVariables = {||};
export type routeConfig_CreatePoll_QueryResponse = {|
  +pollProgram: ?{|
    +$fragmentRefs: CreateEditPollPage_pollProgram$ref
  |}
|};
export type routeConfig_CreatePoll_Query = {|
  variables: routeConfig_CreatePoll_QueryVariables,
  response: routeConfig_CreatePoll_QueryResponse,
|};
*/


/*
query routeConfig_CreatePoll_Query {
  pollProgram(itemId: 1) {
    ...CreateEditPollPage_pollProgram
    id
  }
}

fragment CreateEditPollPage_pollProgram on PollProgram {
  id
  options {
    key
    value
  }
  ...PollProgramHeader_pollProgram
}

fragment PollProgramHeader_pollProgram on PollProgram {
  id
  itemId
  name
  image {
    url
    width
    height
  }
  options {
    key
    value
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "itemId",
    "value": 1
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_CreatePoll_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "PollProgram",
        "kind": "LinkedField",
        "name": "pollProgram",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateEditPollPage_pollProgram"
          }
        ],
        "storageKey": "pollProgram(itemId:1)"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routeConfig_CreatePoll_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "PollProgram",
        "kind": "LinkedField",
        "name": "pollProgram",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Option",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "itemId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "pollProgram(itemId:1)"
      }
    ]
  },
  "params": {
    "id": "e5a608bd1f761824bcefeef6dd908f3c",
    "metadata": {},
    "name": "routeConfig_CreatePoll_Query",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78625608d98fdcd6006c7ab0de1dd2c6';

module.exports = node;
