/**
 * @flow
 * @relayHash 4473eb8c7ecd40dc10cbffe79a91bfca
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProgramPage_fulfillmentProgram$ref = any;
type ProgramPage_latestProgram$ref = any;
type ProgramPage_leadersProgram$ref = any;
type ProgramPage_program$ref = any;
export type routeConfig_Program_QueryVariables = {|
  itemId?: ?number
|};
export type routeConfig_Program_QueryResponse = {|
  +program: ?{|
    +$fragmentRefs: ProgramPage_program$ref
  |},
  +fulfillmentProgram: ?{|
    +$fragmentRefs: ProgramPage_fulfillmentProgram$ref
  |},
  +latestProgram: ?{|
    +$fragmentRefs: ProgramPage_latestProgram$ref
  |},
  +leadersProgram: ?{|
    +$fragmentRefs: ProgramPage_leadersProgram$ref
  |},
|};
export type routeConfig_Program_Query = {|
  variables: routeConfig_Program_QueryVariables,
  response: routeConfig_Program_QueryResponse,
|};
*/


/*
query routeConfig_Program_Query(
  $itemId: Int
) {
  program(itemId: $itemId) {
    ...ProgramPage_program
    id
  }
  fulfillmentProgram: program(itemId: $itemId) {
    ...ProgramPage_fulfillmentProgram
    id
  }
  latestProgram: program(itemId: $itemId) {
    ...ProgramPage_latestProgram
    id
  }
  leadersProgram: program(itemId: $itemId) {
    ...ProgramPage_leadersProgram
    id
  }
}

fragment ProgramFulfillmentTable_userSource_2YBwyy on Program {
  itemId
  users: fulfillments(first: 10, orderBy: FULFILLED_AT_DESC) {
    totalCount
    edges {
      cursor
      node {
        id
        createdAt
        fulfilledAt
        rewards {
          title
          id
        }
        referralCode {
          id
          code
          referralCount
          referrer {
            code
            subscribers {
              id
              email
              username
            }
            id
          }
          subscribers {
            id
            email
            username
          }
        }
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}

fragment ProgramHeader_program on Program {
  id
  itemId
  name
  image {
    url
    width
    height
  }
  options {
    key
    value
  }
}

fragment ProgramLatestTable_userSource_vSYC on Program {
  itemId
  users: referralCodes(first: 10, orderBy: CREATED_AT_DESC, referrals: true) {
    totalCount
    edges {
      cursor
      node {
        id
        code
        createdAt
        referralCount
        referralRank
        referrer {
          code
          subscribers {
            id
            email
            username
          }
          id
        }
        subscribers {
          id
          email
          username
        }
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}

fragment ProgramLeadersTable_userSource_3Sjo3N on Program {
  itemId
  users: referralCodes(first: 10, orderBy: REFERRAL_COUNT_DESC, referrers: true, withRank: true) {
    totalCount
    edges {
      cursor
      node {
        id
        code
        createdAt
        referralCount
        referralRank
        referrer {
          code
          subscribers {
            id
            email
            username
          }
          id
        }
        subscribers {
          id
          email
          username
        }
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}

fragment ProgramPage_fulfillmentProgram on Program {
  ...ProgramFulfillmentTable_userSource_2YBwyy
}

fragment ProgramPage_latestProgram on Program {
  ...ProgramLatestTable_userSource_vSYC
}

fragment ProgramPage_leadersProgram on Program {
  ...ProgramLeadersTable_userSource_3Sjo3N
}

fragment ProgramPage_program on Program {
  itemId
  options {
    key
    value
  }
  ...ProgramHeader_program
  ...ReferralGrowthChart_program
  ...ReferralTotals_program
  ...RewardsList_program
}

fragment ReferralGrowthChart_program on Program {
  growth {
    label
    referralCount
    referrerCount
  }
}

fragment ReferralTotals_program on Program {
  totalReferrals: referralCodes(referrals: true) {
    totalCount
  }
  totalReferrers: referralCodes(referrers: true) {
    totalCount
  }
}

fragment RewardsList_program on Program {
  id
  rewards {
    id
    title
    description
    referralsNeeded
    archivedAt
    image {
      url
      width
      height
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "itemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "itemId",
    "variableName": "itemId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referralCount",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "referrals",
  "value": true
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "kind": "Literal",
  "name": "referrers",
  "value": true
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v12 = [
  (v11/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "FULFILLED_AT_DESC"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscriber",
  "kind": "LinkedField",
  "name": "subscribers",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "ReferralCode",
  "kind": "LinkedField",
  "name": "referrer",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v16/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = [
  (v11/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT_DESC"
  },
  (v6/*: any*/)
],
v21 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "ReferralCodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      (v13/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ReferralCode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v15/*: any*/),
          (v14/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referralRank",
            "storageKey": null
          },
          (v17/*: any*/),
          (v16/*: any*/),
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v19/*: any*/)
],
v22 = [
  "orderBy",
  "referrals",
  "referrers",
  "withCount",
  "withRank"
],
v23 = [
  (v11/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "REFERRAL_COUNT_DESC"
  },
  (v9/*: any*/),
  {
    "kind": "Literal",
    "name": "withRank",
    "value": true
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_Program_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProgramPage_program"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "fulfillmentProgram",
        "args": (v1/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProgramPage_fulfillmentProgram"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "latestProgram",
        "args": (v1/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProgramPage_latestProgram"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "leadersProgram",
        "args": (v1/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProgramPage_leadersProgram"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeConfig_Program_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Option",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgramGrowth",
            "kind": "LinkedField",
            "name": "growth",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referrerCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "totalReferrals",
            "args": [
              (v6/*: any*/)
            ],
            "concreteType": "ReferralCodeConnection",
            "kind": "LinkedField",
            "name": "referralCodes",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "referralCodes(referrals:true)"
          },
          {
            "alias": "totalReferrers",
            "args": [
              (v9/*: any*/)
            ],
            "concreteType": "ReferralCodeConnection",
            "kind": "LinkedField",
            "name": "referralCodes",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": "referralCodes(referrers:true)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Reward",
            "kind": "LinkedField",
            "name": "rewards",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralsNeeded",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archivedAt",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "fulfillmentProgram",
        "args": (v1/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "users",
            "args": (v12/*: any*/),
            "concreteType": "FulfillmentConnection",
            "kind": "LinkedField",
            "name": "fulfillments",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FulfillmentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Fulfillment",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fulfilledAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Reward",
                        "kind": "LinkedField",
                        "name": "rewards",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReferralCode",
                        "kind": "LinkedField",
                        "name": "referralCode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v15/*: any*/),
                          (v5/*: any*/),
                          (v17/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v19/*: any*/)
            ],
            "storageKey": "fulfillments(first:10,orderBy:\"FULFILLED_AT_DESC\")"
          },
          {
            "alias": "users",
            "args": (v12/*: any*/),
            "filters": [
              "orderBy",
              "fulfilled",
              "referralCodeId",
              "fulfilledStart",
              "fulfilledEnd"
            ],
            "handle": "connection",
            "key": "ProgramFulfillmentTable_users",
            "kind": "LinkedHandle",
            "name": "fulfillments"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "latestProgram",
        "args": (v1/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "users",
            "args": (v20/*: any*/),
            "concreteType": "ReferralCodeConnection",
            "kind": "LinkedField",
            "name": "referralCodes",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": "referralCodes(first:10,orderBy:\"CREATED_AT_DESC\",referrals:true)"
          },
          {
            "alias": "users",
            "args": (v20/*: any*/),
            "filters": (v22/*: any*/),
            "handle": "connection",
            "key": "ProgramLatestTable_users",
            "kind": "LinkedHandle",
            "name": "referralCodes"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "leadersProgram",
        "args": (v1/*: any*/),
        "concreteType": "Program",
        "kind": "LinkedField",
        "name": "program",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "users",
            "args": (v23/*: any*/),
            "concreteType": "ReferralCodeConnection",
            "kind": "LinkedField",
            "name": "referralCodes",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": "referralCodes(first:10,orderBy:\"REFERRAL_COUNT_DESC\",referrers:true,withRank:true)"
          },
          {
            "alias": "users",
            "args": (v23/*: any*/),
            "filters": (v22/*: any*/),
            "handle": "connection",
            "key": "ProgramLeadersTable_users",
            "kind": "LinkedHandle",
            "name": "referralCodes"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "4473eb8c7ecd40dc10cbffe79a91bfca",
    "metadata": {},
    "name": "routeConfig_Program_Query",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12a7b63cba7917480277631943ab3ad3';

module.exports = node;
