/**
 * @flow
 * @relayHash d310ad70ef9a44f09e82cedf4f4dac55
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NewProgramPage_accounts$ref = any;
export type routeConfig_NewProgram_QueryVariables = {||};
export type routeConfig_NewProgram_QueryResponse = {|
  +accounts: ?{|
    +$fragmentRefs: NewProgramPage_accounts$ref
  |}
|};
export type routeConfig_NewProgram_Query = {|
  variables: routeConfig_NewProgram_QueryVariables,
  response: routeConfig_NewProgram_QueryResponse,
|};
*/


/*
query routeConfig_NewProgram_Query {
  accounts {
    ...NewProgramPage_accounts
  }
}

fragment NewProgramPage_accounts on AccountConnection {
  edges {
    node {
      id
      itemId
      name
      lists {
        id
        itemId
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_NewProgram_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountConnection",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewProgramPage_accounts"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routeConfig_NewProgram_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountConnection",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "List",
                    "kind": "LinkedField",
                    "name": "lists",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "d310ad70ef9a44f09e82cedf4f4dac55",
    "metadata": {},
    "name": "routeConfig_NewProgram_Query",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a186e88a158eea1e7b2572cd8836038';

module.exports = node;
