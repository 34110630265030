import React from 'react';
import { Container } from 'react-bootstrap';
import LogoType from '../images/logo-scalemail-type.svg';

export default () => {
  return (
    <footer className="section app-footer">
      <Container as="nav">
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="https://scalemail.co/privacy">Privacy</a>
          </li>
          <li className="list-inline-item">
            <a href="https://scalemail.co/terms">Terms</a>
          </li>
          <li className="list-inline-item">
            <a href="https://scalemail.co"><LogoType width="144" height="24" /></a>
          </li>
        </ul>
      </Container>
    </footer>
  );
}