import React from 'react';

export default () => (
  <aside style={{
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    Loading&hellip;
  </aside>
);