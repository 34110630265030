import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { createFragmentContainer } from 'react-relay';
import { setPollPrograms, setPrograms } from '../actions';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LoadingIndicator from '../components/LoadingIndicator';

const AppPage = ({ children, pollPrograms, programs, setPollPrograms, setPrograms, retry, viewer }) => {
  useEffect(() => {
    if (pollPrograms) {
      setPollPrograms(pollPrograms.edges.map(({ node }) => node));
    }
  }, [pollPrograms, setPollPrograms]);

  useEffect(() => {
    if (programs) {
      setPrograms(programs.edges.map(({ node }) => node));
    }
  }, [programs, setPrograms]);

  return (
    <div className="app" data-user-id={1}>
      <Header {...{pollPrograms, programs, retry}} />
      <Suspense fallback={<LoadingIndicator />}>
        { children }
      </Suspense>
      <Footer />
    </div>
  )
};

const mapDispatchToProps = dispatch => ({
  setPollPrograms(pollPrograms) {
    dispatch(setPollPrograms(pollPrograms));
  },
  setPrograms(programs) {
    dispatch(setPrograms(programs));
  }
});

const ConnectedAppPage = connect(null, mapDispatchToProps)(AppPage);

export default createFragmentContainer(ConnectedAppPage, {
  viewer: graphql`
    fragment AppPage_viewer on User {
      id
    }
  `,
  pollPrograms: graphql`
    fragment AppPage_pollPrograms on PollProgramConnection {
      edges {
        node {
          __typename
          id
          itemId
          name
        }
      }
    }
  `,
  programs: graphql`
    fragment AppPage_programs on ProgramConnection {
      edges {
        node {
          __typename
          id
          itemId
          name
        }
      }
    }
  `
});