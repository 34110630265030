import {createConnectedRouter, createRender } from 'found';
import { Resolver } from 'found-relay';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import environment from '../config/relay-environment';
import * as serviceWorker from '../serviceWorker';
import { store, persistor } from '../store';
import '../styles/index.scss';

if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
  require("preact/debug");
}

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

const Router = createConnectedRouter({
  render: createRender({
    renderError: ({ error }) => (
      <div>{error.status === 404 ? 'Not found' : 'Error'}</div>
    ),
  })
});

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router resolver={new Resolver(environment)} matchContext={{ store, environment }} />
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
