import commitMutation from "relay-commit-mutation-promise";

const mutation = graphql`
  mutation updatePollProgramMutation($input: PollProgramInput!) {
    updatePollProgram(input: $input) {
      id
      name
      image {
        url
        width
        height
      }
      options {
        key
        value
      }
    }
  }
`;

export default function updatePollProgram(environment, input) {
  const variables = { input };

  return commitMutation(environment, {
    mutation,
    variables
  });
}
