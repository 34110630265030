/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AppPage_programs$ref: FragmentReference;
declare export opaque type AppPage_programs$fragmentType: AppPage_programs$ref;
export type AppPage_programs = {|
  +edges: ?$ReadOnlyArray<?{|
    +node: ?{|
      +__typename: string,
      +id: string,
      +itemId: ?number,
      +name: ?string,
    |}
  |}>,
  +$refType: AppPage_programs$ref,
|};
export type AppPage_programs$data = AppPage_programs;
export type AppPage_programs$key = {
  +$data?: AppPage_programs$data,
  +$fragmentRefs: AppPage_programs$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppPage_programs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProgramEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Program",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProgramConnection",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'f398f643a0dd34560c9cd35a89f49291';

module.exports = node;
