/**
 * @flow
 * @relayHash d6a1c5ce6ff61c14ab5fbf873bb86f13
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProgramInput = {|
  id?: ?string,
  image?: ?any,
  itemId?: ?number,
  listIds?: ?$ReadOnlyArray<string>,
  name?: ?string,
  options?: ?$ReadOnlyArray<OptionInput>,
|};
export type OptionInput = {|
  key: string,
  value?: ?string,
|};
export type updateProgramMutationVariables = {|
  input: ProgramInput
|};
export type updateProgramMutationResponse = {|
  +updateProgram: {|
    +id: string,
    +name: ?string,
    +image: ?{|
      +url: ?string,
      +width: ?string,
      +height: ?string,
    |},
    +options: ?$ReadOnlyArray<{|
      +key: string,
      +value: ?string,
    |}>,
  |}
|};
export type updateProgramMutation = {|
  variables: updateProgramMutationVariables,
  response: updateProgramMutationResponse,
|};
*/


/*
mutation updateProgramMutation(
  $input: ProgramInput!
) {
  updateProgram(input: $input) {
    id
    name
    image {
      url
      width
      height
    }
    options {
      key
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Program",
    "kind": "LinkedField",
    "name": "updateProgram",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Image",
        "kind": "LinkedField",
        "name": "image",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Option",
        "kind": "LinkedField",
        "name": "options",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateProgramMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateProgramMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d6a1c5ce6ff61c14ab5fbf873bb86f13",
    "metadata": {},
    "name": "updateProgramMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a3a346279e8d0033fc3c7bf9cf5c4186';

module.exports = node;
