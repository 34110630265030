/**
 * @flow
 * @relayHash ca4897d9dfafe95ae9e3bf9465481d37
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PollProgramPage_latestProgram$ref = any;
type PollProgramPage_pollProgram$ref = any;
export type routeConfig_PollProgram_QueryVariables = {||};
export type routeConfig_PollProgram_QueryResponse = {|
  +pollProgram: ?{|
    +$fragmentRefs: PollProgramPage_pollProgram$ref
  |},
  +latestProgram: ?{|
    +$fragmentRefs: PollProgramPage_latestProgram$ref
  |},
|};
export type routeConfig_PollProgram_Query = {|
  variables: routeConfig_PollProgram_QueryVariables,
  response: routeConfig_PollProgram_QueryResponse,
|};
*/


/*
query routeConfig_PollProgram_Query {
  pollProgram(itemId: 1) {
    ...PollProgramPage_pollProgram
    id
  }
  latestProgram: pollProgram(itemId: 1) {
    ...PollProgramPage_latestProgram
    id
  }
}

fragment LatestPollsList_pollSource_41muc4 on PollProgram {
  itemId
  polls(first: 5, orderBy: CREATED_AT_DESC) {
    totalCount
    edges {
      cursor
      node {
        id
        itemId
        createdAt
        prompt
        responseCount
        choices {
          id
          description
          responseCount
          responseRatio
        }
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}

fragment PollProgramHeader_pollProgram on PollProgram {
  id
  itemId
  name
  image {
    url
    width
    height
  }
  options {
    key
    value
  }
}

fragment PollProgramPage_latestProgram on PollProgram {
  ...LatestPollsList_pollSource_41muc4
}

fragment PollProgramPage_pollProgram on PollProgram {
  itemId
  options {
    key
    value
  }
  ...PollProgramHeader_pollProgram
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "itemId",
    "value": 1
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT_DESC"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "responseCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_PollProgram_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "PollProgram",
        "kind": "LinkedField",
        "name": "pollProgram",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PollProgramPage_pollProgram"
          }
        ],
        "storageKey": "pollProgram(itemId:1)"
      },
      {
        "alias": "latestProgram",
        "args": (v0/*: any*/),
        "concreteType": "PollProgram",
        "kind": "LinkedField",
        "name": "pollProgram",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PollProgramPage_latestProgram"
          }
        ],
        "storageKey": "pollProgram(itemId:1)"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routeConfig_PollProgram_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "PollProgram",
        "kind": "LinkedField",
        "name": "pollProgram",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Option",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "pollProgram(itemId:1)"
      },
      {
        "alias": "latestProgram",
        "args": (v0/*: any*/),
        "concreteType": "PollProgram",
        "kind": "LinkedField",
        "name": "pollProgram",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "PollConnection",
            "kind": "LinkedField",
            "name": "polls",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PollEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Poll",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "prompt",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Choice",
                        "kind": "LinkedField",
                        "name": "choices",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "responseRatio",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "polls(first:5,orderBy:\"CREATED_AT_DESC\")"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [
              "orderBy"
            ],
            "handle": "connection",
            "key": "LatestPollsList_polls",
            "kind": "LinkedHandle",
            "name": "polls"
          },
          (v2/*: any*/)
        ],
        "storageKey": "pollProgram(itemId:1)"
      }
    ]
  },
  "params": {
    "id": "ca4897d9dfafe95ae9e3bf9465481d37",
    "metadata": {},
    "name": "routeConfig_PollProgram_Query",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c6afe2cd1e38e442e459245d45d0f7c5';

module.exports = node;
