import commitMutation from "relay-commit-mutation-promise";

const mutation = graphql`
  mutation updateProgramMutation($input: ProgramInput!) {
    updateProgram(input: $input) {
      id
      name
      image {
        url
        width
        height
      }
      options {
        key
        value
      }
    }
  }
`;

export default function updateProgram(environment, input) {
  const variables = { input };

  return commitMutation(environment, {
    mutation,
    variables
  });
}
