import commitMutation from "relay-commit-mutation-promise";

const mutation = graphql`
  mutation updateUserMutation($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        id
        firstName
        lastName
        email
        token
      }
      errors {
        message
        path
      }
    }
  }
`;

export default function updateUser(environment, input) {
  const variables = { input };

  return commitMutation(environment, {
    mutation,
    variables
  });
}
