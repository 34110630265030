import React, { useState } from "react";
import { Alert, Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import updateUser from "../mutations/updateUser";
import environment from "../config/relay-environment";
import { connect } from 'react-redux';
import { setViewer } from "../actions";

const EditProfileModal = ({ app, retry, isLoading, setIsLoading, setCurrentModal, setViewer, show }) => {
  const { viewer = {} } = app;
  const { firstName = '', lastName = '' } = viewer;
  const [ error, setError ] = useState();
  
  const editProfileResult = ({ userUpdate }) => {
    const { errors, user } = userUpdate;
    setIsLoading(false);
    if (errors) {
      setError(errors[0].message);
    } else {
      setCurrentModal();
      if (user && user.token) {
        setViewer(user);
      }
    }
  };

  const handleEditProfileSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    setError();
    const form = event.currentTarget;
    const userInput = {
      firstName: form.elements["first-name"].value,
      lastName: form.elements["last-name"].value
    };

    const currentPassword = form.elements["current-password"].value;
    if (currentPassword.length) {
      const password = form.elements["password"].value;
      const passwordConfirmation = form.elements["password-confirmation"].value;
      if (password.length) {
        if (password === currentPassword) {
          setIsLoading(false);
          return setError("New password can't be the same as current password.");
        } else if (password !== passwordConfirmation) {
          setIsLoading(false);
          return setError("Passwords don't match.");
        } else {
          userInput.currentPassword = currentPassword;
          userInput.password = password;
          userInput.passwordConfirmation = passwordConfirmation;
        }
      }
    }

    updateUser(environment, userInput)
      .then(editProfileResult)
      .then(retry)
      .catch(error => console.error(error));
  }

  return (
    <Modal
      show={show}
      onHide={setCurrentModal}
      aria-labelledby="edit-profile"
      size="lg"
      centered
    >
      <Form onSubmit={handleEditProfileSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="edit-profile" as="h4">
            Edit Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Form.Row}>
            <Form.Label column sm={4} htmlFor="first-name">Name</Form.Label>
            <Col sm={4}>
              <Form.Control id="first-name" type="text" placeholder="First name" defaultValue={firstName} autoComplete="given-name" />
            </Col>
            <Col sm={4}>
              <Form.Control id="last-name" type="text" placeholder="Last name" defaultValue={lastName} autoComplete="family-name" />
            </Col>
          </Form.Group>
          <Form.Group as={Form.Row} className='mt-5'>
            <Form.Label column sm={4} htmlFor="current-password">Change Password</Form.Label>
            <Col sm={8}>
              <Form.Group as={Form.Row}>
                <Col sm={12}>
                  <Form.Control id="current-password" type="password" placeholder="Current password" autoComplete="current-password" />
                </Col>
              </Form.Group>
              <Form.Group as={Form.Row}>
                <Col sm={6}>
                  <Form.Control id="password" type="password" placeholder="New password" autoComplete="new-password" aria-describedby="password-help" />
                </Col>
                <Col sm={6}>
                  <Form.Control id="password-confirmation" type="password" placeholder="New password again" autoComplete="new-password" />
                </Col>
              </Form.Group>
              <Form.Text id="password-help" muted>
                8+ characters with at least one letter and one number or special character.
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group>
            { !error ? null : <Alert variant='warning'>{error}</Alert> }
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {isLoading && (
            <Spinner animation="grow" role="status" variant="primary">
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
          <Button type="submit">Save</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => ({
  setViewer(user) {
    dispatch(setViewer(user));
  }
})

export default connect(undefined, mapDispatchToProps)(EditProfileModal);