import { Actions as FarceActions, BrowserProtocol, createHistoryEnhancer, queryMiddleware } from 'farce';
import { createMatchEnhancer, Matcher } from 'found';
import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import routeConfig from '../config/route-config'
import rootReducer from '../reducers';

const reduxDevTools = () => {
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    return window.__REDUX_DEVTOOLS_EXTENSION__();
  }
  return enhancers => enhancers;
}

function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      createHistoryEnhancer({
        protocol: new BrowserProtocol(),
        middlewares: [queryMiddleware],
      }),
      createMatchEnhancer(new Matcher(routeConfig)),
      reduxDevTools()
    )
  );

  store.dispatch(FarceActions.init());
  const persistor = persistStore(store);
  return {
    store,
    persistor
  };
}

export const { store, persistor } = configureStore();