/**
 * @flow
 * @relayHash 67f64f8eed8304b5b590098ae937874d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PublicReferPage_referralCode$ref = any;
type routeConfig_PublicRedirectRoute_referralCode$ref = any;
export type routeConfig_PublicRefer_QueryVariables = {|
  code?: ?string
|};
export type routeConfig_PublicRefer_QueryResponse = {|
  +referralCode: ?{|
    +$fragmentRefs: PublicReferPage_referralCode$ref & routeConfig_PublicRedirectRoute_referralCode$ref
  |}
|};
export type routeConfig_PublicRefer_Query = {|
  variables: routeConfig_PublicRefer_QueryVariables,
  response: routeConfig_PublicRefer_QueryResponse,
|};
*/


/*
query routeConfig_PublicRefer_Query(
  $code: String
) {
  referralCode(code: $code) {
    ...PublicReferPage_referralCode
    ...routeConfig_PublicRedirectRoute_referralCode
    id
  }
}

fragment ProgramHeader_program on Program {
  id
  itemId
  name
  image {
    url
    width
    height
  }
  options {
    key
    value
  }
}

fragment PublicReferPage_referralCode on ReferralCode {
  code
  createdAt
  program {
    itemId
    options {
      key
      value
    }
    ...ProgramHeader_program
    id
  }
}

fragment routeConfig_PublicRedirectRoute_referralCode on ReferralCode {
  code
  program {
    options {
      key
      value
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "code",
    "variableName": "code"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Option",
  "kind": "LinkedField",
  "name": "options",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_PublicRefer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferralCode",
        "kind": "LinkedField",
        "name": "referralCode",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PublicReferPage_referralCode"
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "routeConfig_PublicRedirectRoute_referralCode",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Program",
                "kind": "LinkedField",
                "name": "program",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeConfig_PublicRefer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferralCode",
        "kind": "LinkedField",
        "name": "referralCode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Program",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "itemId",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "67f64f8eed8304b5b590098ae937874d",
    "metadata": {},
    "name": "routeConfig_PublicRefer_Query",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '22a7ec663cc911f88c266ff6c3f0b54b';

module.exports = node;
