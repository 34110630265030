/**
 * @flow
 * @relayHash d42f9f2ace3c2669b32639e3d3e5d282
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PollProgramInput = {|
  id?: ?string,
  image?: ?any,
  itemId?: ?number,
  listId?: ?string,
  name?: ?string,
  options?: ?$ReadOnlyArray<OptionInput>,
|};
export type OptionInput = {|
  key: string,
  value?: ?string,
|};
export type updatePollProgramMutationVariables = {|
  input: PollProgramInput
|};
export type updatePollProgramMutationResponse = {|
  +updatePollProgram: {|
    +id: string,
    +name: ?string,
    +image: ?{|
      +url: ?string,
      +width: ?string,
      +height: ?string,
    |},
    +options: ?$ReadOnlyArray<{|
      +key: string,
      +value: ?string,
    |}>,
  |}
|};
export type updatePollProgramMutation = {|
  variables: updatePollProgramMutationVariables,
  response: updatePollProgramMutationResponse,
|};
*/


/*
mutation updatePollProgramMutation(
  $input: PollProgramInput!
) {
  updatePollProgram(input: $input) {
    id
    name
    image {
      url
      width
      height
    }
    options {
      key
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PollProgram",
    "kind": "LinkedField",
    "name": "updatePollProgram",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Image",
        "kind": "LinkedField",
        "name": "image",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Option",
        "kind": "LinkedField",
        "name": "options",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePollProgramMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePollProgramMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d42f9f2ace3c2669b32639e3d3e5d282",
    "metadata": {},
    "name": "updatePollProgramMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '91a980854979f30507e1a5bd5e304fbd';

module.exports = node;
