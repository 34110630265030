/**
 * @flow
 * @relayHash e39abcffc31165eb37152c450eb97e7d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PublicReferPage_referralCode$ref = any;
export type routeConfig_IframeRefer_QueryVariables = {|
  code?: ?string
|};
export type routeConfig_IframeRefer_QueryResponse = {|
  +referralCode: ?{|
    +$fragmentRefs: PublicReferPage_referralCode$ref
  |}
|};
export type routeConfig_IframeRefer_Query = {|
  variables: routeConfig_IframeRefer_QueryVariables,
  response: routeConfig_IframeRefer_QueryResponse,
|};
*/


/*
query routeConfig_IframeRefer_Query(
  $code: String
) {
  referralCode(code: $code) {
    ...PublicReferPage_referralCode
    id
  }
}

fragment ProgramHeader_program on Program {
  id
  itemId
  name
  image {
    url
    width
    height
  }
  options {
    key
    value
  }
}

fragment PublicReferPage_referralCode on ReferralCode {
  code
  createdAt
  program {
    itemId
    options {
      key
      value
    }
    ...ProgramHeader_program
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "code",
    "variableName": "code"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_IframeRefer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferralCode",
        "kind": "LinkedField",
        "name": "referralCode",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PublicReferPage_referralCode"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeConfig_IframeRefer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferralCode",
        "kind": "LinkedField",
        "name": "referralCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Program",
            "kind": "LinkedField",
            "name": "program",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "itemId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Option",
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "e39abcffc31165eb37152c450eb97e7d",
    "metadata": {},
    "name": "routeConfig_IframeRefer_Query",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '29f50b9f972a72b1eef4d2fb1a88a840';

module.exports = node;
