import { foundReducer } from 'found';
import { combineReducers } from 'redux';
import { SET_CURRENT_PROGRAM, SET_PROGRAM_OPTIONS, SET_PROGRAMS, SET_VIEWER } from '../constants/ActionTypes';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
  programId: null,
  viewer: null
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PROGRAM:
      return { ...state, currentProgram: action.currentProgram };

    case SET_PROGRAM_OPTIONS:
      const {
        use_code_as_username: useCodeAsUsername,
        use_single_opt_in: useSingleOptIn,
        primary_color: primaryColor,
        refer_url: referURL,
        display_default_refer_url: displayDefaultReferURL,
        user_url: userURL,
        redirect_url: redirectURL,
        subscribe_heading: subscribeHeading,
        subscribe_text: subscribeText,
        hide_response_count: hideResponseCount,
        email_css: emailCSS,
        web_css: webCSS,
      } = action;
      return {
        ...state,
        useCodeAsUsername,
        useSingleOptIn,
        primaryColor,
        referURL,
        displayDefaultReferURL,
        userURL,
        redirectURL,
        subscribeHeading,
        subscribeText,
        hideResponseCount,
        emailCSS,
        webCSS,
      };

    case SET_PROGRAMS:
      return { ...state, programs: action.programs };

    case SET_VIEWER:
      return { ...state, viewer: action.viewer };

    default:
      return state;
  }
};

const persistConfig = { key: 'root', storage };
const persistedReducer = persistReducer(persistConfig, appReducer);

const rootReducer = combineReducers({
  app: persistedReducer,
  found: foundReducer
});

export default rootReducer;
